<template>
    <q-card class="my-card" v-if="openLoan" :class="$q.screen.lg ? 'desktop-height': ''" >
      <q-card-section>
        <q-list bordered separator class="my-border">
          <q-item>
            <q-item-section>
              <span class="text-subtitle1 text-bold">Loan Payment Options</span>
              <br>
              <span class="" :class="$q.screen.lg ? 'text-body1' : 'text-12'">
                Pay off your loan in full now. Paying your loan off early will stop any additional interest from accruing.
            </span>
              <q-card-actions class="q-pb-lg row justify-center">
                <q-btn class="bgBtnLiner text-white" id="btn_early_settlement"
                       @click="currentAction = 'PayEarlySettlement'" rounded label="Pay Off Now"/>
              </q-card-actions>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>

      <q-card-section v-if="!customer.hasIncomeAndExpenditures()">
        <q-list bordered separator class="my-border">
          <q-item clickable @click="currentAction = 'PaymentPlanPresets'" style="min-height: 100px">
            <q-item-section side>
              <q-icon color="light-blue-5" name="autorenew"/>
            </q-item-section>
            <q-item-section>
                <span class="text-subtitle1 text-bold">
                  Self Service Loan Centre
                </span>
               <span class="text-weight-thin">
                   Choose other payment plan - modification
                </span>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
      <!--
      <q-card-actions class="items-center">
        <q-btn class="full-width text-light-blue-6" @click="currentAction = 'PayOnePayment'" outline rounded
               id="btn_partial_payment" label="MAKE A ONE TIME PAYMENT"/>
      </q-card-actions>
      -->
      <q-dialog :value="!!currentAction" persistent  :full-width="$q.screen.xs" :full-height="$q.screen.xs"  @hide="currentAction = null">
        <component v-if="currentAction" :is="currentAction" @failure="onFailure" @goTo="goToComponent" @success="onSuccess" v-bind="actionProps"/>
      </q-dialog>
    </q-card>
</template>

<script>
import PayOnePayment from './PayOnePayment'
import PayEarlySettlement from './PayEarlySettlement'
import PaymentPlanPresets from './PaymentPlanPresets'
import AddCreditCard from './AddCreditCard'
import EventBus from '../utils/even-bus'

export default {
  name: 'loan-actions',
  components: {
    PayEarlySettlement,
    PayOnePayment,
    AddCreditCard,
    PaymentPlanPresets
  },
  data () {
    return {
      currentAction: null
    }
  },
  mounted () {
    EventBus.$on('open-action', (data) => {
      this.currentAction = data
    })
  },
  methods: {
    onFailure () {
      this.currentAction = null
    },
    onSuccess () {
      this.currentAction = null
      this.$dispatch([
        'loans/getLoans',
        'transactions/getTransactions'
      ], true)
    },
    goToComponent (e) {
      this.currentAction = e
    }
  },
  computed: {
    loans () {
      return this.$store.state.loans.loans
    },
    customer () {
      return this.$store.state.customer.customer
    },
    openLoan () {
      return this.loans ? this.loans.find(loan => loan.isOpen()) : null
    },
    // applicableActions () {
    //   return this.actions.filter(action => action.applicable())
    // },
    actionProps () {
      if (!this.currentAction) return {}

      switch (this.currentAction) {
        case 'PayDueRepayment':
        case 'PayPartialRepayment':
          return {
            repayment: this.dueRepayment()
          }
        default:
          return {}
      }
    }
  }
}
</script>

<style scoped>
  .desktop-height {
    height: 400px;
  }

  .text-12 {
    font-size: 12px;
  }

  #btn_early_settlement {
    min-width: 170px;
  }
</style>
