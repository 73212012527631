<template>
  <div>
    <div>
      <q-card-section>
        <q-field borderless v-if="options">
          <q-select :options="options" label="Select Payment Plan" v-model="form.preset" @input="resetForm">
            <template v-slot:option="scope">
              <q-item
                v-bind="scope.itemProps"
                v-on="scope.itemEvents"
              >
                <q-item-section>
                  <q-item-label v-html="getPresetTitle(scope.opt.name)"/>
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </q-field>
        <br>
        Warning: Late repayments can cause you serious money problems. For help, go to <a
        href="www.moneyadviceservice.org.uk">moneyadviceservice.org.uk</a>
      </q-card-section>

      <q-card-section v-if="form.preset">
        <q-separator/>

        <ArrangementCallback v-if="form.preset.name === 'other'" @success="$emit('success')"/>

        <div v-else>
          <div class="q-pa-lg">
            <div class="text-subtitle1 text-bold">
              Please enter your requested payment plan details below.
            </div>
            <div class="text-body5">
              If your request is not automatically approved, you can send a Callback Request and we will get back to you to discuss your proposed payment schedule.
            </div>
            <q-field borderless :key="i" v-for="(field, i) in fields(form.preset.value)">
              <component
                :ref="field.name"
                v-if="!field.hide"
                :is="field.component"
                :type="field.type"
                v-model="field.model"
                v-bind="field.props || {}"
                :label="field.label"
                v-on="field.events"
                :rules="field.roles ? field.roles : [val => !!val || 'Field is required']">

                <!--                <q-popup-proxy v-if="field.name === 'deferInterest'" style="width: 80%">-->
                <!--                  <q-card>-->
                <!--                    <q-list>-->
                <!--                      <q-item>-->
                <!--                        <q-item-section side>-->
                <!--                          <q-icon name="help"></q-icon>-->
                <!--                        </q-item-section>-->
                <!--                        <q-item-section>-->
                <!--                          Deferring payment will negatively impact your credit record.-->
                <!--                          <br/>-->
                <!--                          Additional interest charge might make payment unaffordable for you.-->
                <!--                          <br/>-->
                <!--                          Make sure payment terms are affordable as missing additional payment will cause further-->
                <!--                          problems.-->
                <!--                          <br/>-->
                <!--                          Deferral should not be used in cases of financial difficulty - contact us instead to discuss-->
                <!--                          your account.-->
                <!--                        </q-item-section>-->
                <!--                      </q-item>-->
                <!--                      <q-item class="justify-start">-->
                <!--                        <q-btn @click="field.model = true" label="I Acknowledge" v-close-popup></q-btn>-->
                <!--                        <q-btn @click="field.model = false" label="Cancel" class="q-ml-lg" v-close-popup></q-btn>-->
                <!--                      </q-item>-->
                <!--                    </q-list>-->
                <!--                  </q-card>-->
                <!--                </q-popup-proxy>-->

              </component>
            </q-field>
          </div>

          <q-item class="justify-center">
            <q-btn class="text-center" icon="cp_orange_card" label="Preview Repayments" @click="showPreview" rounded/>
            <q-btn class="bg-orange-7 text-white q-ml-lg" rounded
                   type="submit" @click="callback"
                   v-if="form.preset"
                   label="Send Callback Request"/>
          </q-item>
          <PayPlanRepayments v-if="form.repayments" :repayments="form.repayments"/>
        </div>
      </q-card-section>
    </div>

    <q-card-actions align="center">
      <q-btn class="bgBtnLiner text-white" rounded
             type="submit" @click="submit"
             v-if="form.repayments.length > 0"
             label="Create New payment plan"/>
    </q-card-actions>

    <q-dialog style="background:white" :value="IandEDialogOpen" persistent :full-width="$q.screen.xs"
              :full-height="$q.screen.xs" @hide="currentAction = null">
      <confirm-income @confirm="confirmIandE" :amount="form.params.amount"></confirm-income>
    </q-dialog>

    <q-dialog style="background:white" :value="arrangementDialogOpen" persistent :full-width="$q.screen.xs"
              :full-height="$q.screen.xs" @hide="arrangementDialogOpen=false">
      <confirm-arrangement @accept="onArrangmentAccept" :is-arrangement="isArrangement"
                           :defered-interest="deferInterest"></confirm-arrangement>
    </q-dialog>

  </div>
</template>

<script>
import moment from 'moment'
import Api from '../utils/api'
import EventBus from '../utils/even-bus'
import ConfirmIncome from './ConfirmIncome'
import PayPlanRepayments from './PayPlanRepayments'
import QDateInput from './QDateInput'
import PayFrequencyPlan from './PayFrequencyPlan'
import ArrangementCallback from './ArrangementCallback'
import ConfirmArrangement from './ConfirmArrangement'

export default {
  name: 'pay-plan-form',
  props: {
    loan: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      form: {
        preset: null,
        params: {},
        repayments: []
      },
      deferInterest: false,
      confirmedIandE: false,
      confirmedArrangement: false,
      IandEDialogOpen: false,
      arrangementDialogOpen: false,
      presetTitles: {
        'cif': 'Close Your Loan in Full',
        'early-repayment-modification': 'Make an Extra Early Payment',
        'payment-frequency-modification': 'Modify your Payment Frequency',
        'specific-paydate-modification': 'Change your Pay Date',
        'current-payment-arrangement': 'Update Upcoming Payment',
        'single-overdue-repayment': 'Make a Missed Payment',
        'multiple-overdue-single-payment': 'Make a Missed Payment',
        'multiple-overdue-clear-all': 'Make all Missed Payments',
        'multiple-overdue-variable': 'Make a Different Payment',
        'long-term-arrangement': 'Long Term Arrangement',
        'first-paydate-modification': 'First Paydate Update',
        'december-override-modification': 'December Override',
        'other': 'Other Payment Plan'
      },
      arrangementPresets: [
        'single-overdue-repayment',
        'multiple-overdue-single-payment',
        'multiple-overdue-clear-all',
        'multiple-overdue-variable',
        'long-term-arrangement',
        'current-payment-arrangement'
      ],
      presetParams: {
        'cif': [
          {
            name: 'date',
            component: 'QDateInput',
            model: null,
            label: 'Date',
            props: {
              min: moment().format('YYYY/MM/DD'),
              max: moment().add(30, 'days').format('YYYY/MM/DD')
            }
          }
        ],
        'early-repayment-modification': [
          {
            name: 'amount',
            component: 'QInput',
            type: 'number',
            model: null,
            label: 'Amount',
            props: {
              min: 1,
              max: this.loan.earlySettlementAmount()
            }
          },
          {
            name: 'date',
            component: 'QDateInput',
            model: null,
            label: 'Date',
            props: {
              min: moment().format('YYYY/MM/DD'),
              max: moment().add(30, 'days').format('YYYY/MM/DD')
            }
          },
          {
            name: 'deferPayments',
            component: 'QCheckbox',
            model: false,
            label: 'Defer Payments'
          }
        ],
        'payment-frequency-modification': [
          {
            name: 'params',
            component: 'PayFrequencyPlan',
            model: null,
            multiple: true
          },
          // {
          //   name: 'deferForward',
          //   component: 'QCheckbox',
          //   model: false,
          //   label: 'Defer Forward'
          // },
          {
            name: 'deferInterest',
            component: 'QCheckbox',
            model: false,
            label: 'Defer Interest',
            events: {
              input: function (val) {
                this.deferInterest = val
              }.bind(this)
            }
          }
        ],
        'specific-paydate-modification': [
          {
            name: 'date',
            component: 'QDateInput',
            model: null,
            label: 'Date',
            props: {
              min: moment().format('YYYY/MM/DD'),
              max: moment().format('YYYY/MM/DD')
            }
          },
          {
            name: 'deferInterest',
            component: 'QCheckbox',
            model: false,
            label: 'Defer Interest',
            events: {
              input: function (val) {
                this.deferInterest = val
              }.bind(this)
            }
          }
        ],
        'current-payment-arrangement': [
          {
            name: 'amount',
            component: 'QInput',
            model: null,
            label: 'Amount',
            type: 'number'
          },
          {
            name: 'deferInterest',
            component: 'QCheckbox',
            model: true,
            label: 'Defer Interest',
            events: {
              input: function (val) {
                this.deferInterest = val
              }.bind(this)
            }
          }
        ],
        'single-overdue-repayment': [
          {
            name: 'withAccruedInterest',
            component: 'QCheckbox',
            model: true,
            label: 'With Accrued Interest',
            hide: true
          },
          {
            name: 'deferInterest',
            component: 'QCheckbox',
            model: false,
            label: 'Defer Interest',
            events: {
              input: function (val) {
                this.deferInterest = val
              }.bind(this)
            }
          }
        ],
        'multiple-overdue-single-payment': [
          {
            name: 'deferInterest',
            component: 'QCheckbox',
            model: false,
            label: 'Defer Interest',
            events: {
              input: function (val) {
                this.deferInterest = val
              }.bind(this)
            }
          }
        ],
        'multiple-overdue-clear-all': [
          {
            name: 'deferInterest',
            component: 'QCheckbox',
            model: false,
            label: 'Defer Interest'
          }
        ],
        'multiple-overdue-variable': [
          {
            name: 'amount',
            component: 'QInput',
            model: null,
            label: 'Amount',
            type: 'number'
          },
          {
            name: 'deferInterest',
            component: 'QCheckbox',
            model: false,
            label: 'Defer Interest',
            events: {
              input: function (val) {
                this.deferInterest = val
              }.bind(this)
            }
          }
        ],
        'long-term-arrangement': [
          {
            name: 'instalments',
            component: 'QInput',
            model: null,
            label: 'Instalments',
            type: 'number'
          }
        ],
        'first-paydate-modification': [
          {
            name: 'deferInterest',
            component: 'QCheckbox',
            model: false,
            label: 'Defer Interest',
            events: {
              input: function (val) {
                this.deferInterest = val
              }.bind(this)
            }
          }
        ],
        'december-override-modification': [
          {
            name: 'payday',
            component: 'QDateInput',
            model: moment().format('YYYY/MM/DD'),
            label: 'Date',
            props: {
              min: moment().format('YYYY/MM/DD'),
              max: moment().add(30, 'day').format('YYYY/MM/DD')
            }
          }
        ]
      }
    }
  },
  mounted () {
    this.presetParams['specific-paydate-modification'][0].props.min = moment(this.nextRepayment.get('payment_date')).subtract(5, 'days').format('YYYY/MM/DD')
    this.presetParams['specific-paydate-modification'][0].props.max = moment(this.nextRepayment.get('payment_date')).add(5, 'days').format('YYYY/MM/DD')
  },
  computed: {
    isArrangement () {
      return this.form.preset && this.arrangementPresets.includes(this.form.preset.name)
    },
    customer () {
      return this.$store.state.customer.customer
    },
    openLoan () {
      return this.$store.state.loans.loans.find(loan => loan.isOpen())
    },
    nextRepayment () {
      return this.openLoan.firstRepayment()
    },
    options () {
      return [...this.loan.availablePresets().filter(p => p.name !== 'cif' && Object.keys(this.presetTitles).includes(p.name)).map(preset => {
        return {
          label: this.presetTitles[preset.name],
          value: preset.id,
          description: preset.description,
          name: preset.name
        }
      }), { name: 'other', label: 'Other Payment Plan' }]
    }
  },
  methods: {
    onArrangmentAccept () {
      this.arrangementDialogOpen = false
      this.confirmedArrangement = true
      this.submit()
    },
    confirmIandE () {
      this.IandEDialogOpen = false
      this.confirmedIandE = true
      this.showPreview()
    },
    fields (presetId) {
      let preset = this.loan.availablePresets().find(p => p.id === presetId)

      if (!preset) {
        return null
      }
      return this.presetParams[preset.name]
    },
    getPresetTitle (presetName) {
      return this.presetTitles[presetName]
    },
    async showPreview () {
      this.form.params = {}
      let isInvalid = false
      this.fields(this.form.preset.value).forEach((p) => {
        if (p.multiple) {
          this.form.params = { ...this.form.params, ...p.model }
        } else {
          this.form.params[p.name] = p.model
        }

        if (p.model === null) {
          isInvalid = true
        }
      })

      if (isInvalid) {
        this.$q.notify({
          color: 'red-5',
          message: 'All fields are required.',
          timeout: 6000,
          textColor: 'white',
          icon: 'warning'
        })
        return
      }

      // if (this.form.preset.name === 'current-payment-arrangement' &&
      //   && parseFloat(this.form.params.amount) <= (this.loan.nextRepayment.amount() * 0.25)
      //   !this.confirmedIandE) {
      //   this.IandEDialogOpen = true
      //   return
      // }

      let params = { params: this.form.params }
      this.$q.loading.show({ delay: 400, message: 'Loading...' })
      try {
        let { data } = await Api().get(`api/loan/${this.loan.get('id')}/preset/${this.form.preset.value}`, { params })
        this.form.repayments = data
      } catch (e) {
        this.form.repayments = []
        this.$q.notify({
          color: 'red-5',
          message: e.response.data,
          timeout: 6000,
          textColor: 'white',
          icon: 'warning'
        })
      }
      this.$q.loading.hide()
    },
    async submit () {
      // only show deferInterest warning if an additional repayment was indeed added
      if (this.deferInterest) {
        if (this.form.repayments.length <= this.openLoan.repayments().length) {
          this.deferInterest = false
        }
      }

      if ((this.isArrangement || this.deferInterest) && !this.confirmedArrangement) {
        this.arrangementDialogOpen = true
        return
      }

      let successMessage = 'Payment plan was created successfully'

      if ([15, 16, 17, 18].includes(this.form.preset.value)) {
        successMessage += ' and due payment charged'
      }

      let success = await this.$dispatch({
        action: 'loans/createPayplan',
        successMessage,
        params: {
          loan_id: this.loan.id(),
          preset_id: this.form.preset.value,
          params: this.form.params
        }
      })
      if (success) {
        this.$emit('success')
      }
    },
    callback () {
      let desc = 'Customer would like to set up a new payment plan [' + this.form.preset.label + '].\n'
      desc += 'My Parameters are: \n'
      this.fields(this.form.preset.value).forEach((p) => {
        desc += p.label + ': ' + p.model + '\n'
      })
      EventBus.$emit('call-back', desc)
      this.$emit('failure')
    },
    resetForm () {
      this.form.repayments = []
      this.form.params = {}
      this.confirmedArrangement = false

      this.deferInterest = this.form.preset.name === 'current-payment-arrangement'
    }
  },
  components: {
    QDateInput,
    PayPlanRepayments,
    PayFrequencyPlan,
    ArrangementCallback,
    ConfirmIncome,
    ConfirmArrangement
  }
}
</script>

<style>
  .q-dialog__inner > div {
    background: white;
  }
</style>
