<template>
      <q-card class="my-card">
        <q-card-section  class="text-h6 text-bold text-center">
              <br>
              <q-icon name="cp_card" size="lg"></q-icon>
              <br>
              {{ title }}
        </q-card-section>

        <div :key="i" v-for="(repayment, i) of currentRepayments">
          <q-card-section>
            <Repayment :repayment="repayment"/>
          </q-card-section>
          <q-separator/>
        </div>

        <div v-if="currentRepayments.length === 0"  class="text-center q-pa-lg q-ma-lg text-grey-6">
          <q-card-section>
            <q-icon name="cp_missile" size="150px"/>
          </q-card-section>
          <q-card-section >
            <span class="text-h5 text-bold">
              {{ (type === 'remaining-repayments' && !openLoan) ? 'You Loan is Closed' : 'No repayments yet' }}
            </span>
            <br>
            <span class="text-subtitle1 text-bold">
                 {{ type === 'remaining-repayments' ? 'There are no outstanding payments.' : ' We don’t see any records from your history.' }}
            </span>
          </q-card-section>
        </div>
        <q-card-actions align="center">
          <q-btn rounded style="min-width: 100px"  class="text-light-blue-7 outlineColor" outline label="OK" v-close-popup/>
        </q-card-actions>

      </q-card>
</template>

<script>

import Repayment from './Repayment'
import { mapGetters } from 'vuex'

export default {
  name: 'PageRepayments',
  props: ['type'],
  meta: {
    title: 'Repayments'
  },
  components: {
    Repayment
  },
  async mounted () {
    this.$dispatch([
      {
        action: 'loans/getLoans',
        message: 'Loading Loans...'
      }
    ])
  },
  computed: {
    ...mapGetters({
      openLoan: 'loans/openLoan',
      lastLoan: 'loans/lastLoan'
    }),
    loan () {
      return this.openLoan ? this.openLoan : this.lastLoan
    },
    paidRepayments () {
      return this.loan.paidRepayments()
    },
    unpaidRepayments () {
      return this.loan.unpaidRepayments()
    },
    currentRepayments () {
      return this.type === 'paid-repayments' ? this.paidRepayments : this.unpaidRepayments
    },
    title () {
      return (this.type === 'paid-repayments' ? 'Received Payments' : 'Upcoming Payments') + ', Loan - ' + this.loan.get('ref_number')
    }
  }
}
</script>

<style scoped>

</style>
