<template>
  <div style="margin: auto;" :style="maxWidthDynamic">
    <div class="row flex-center q-mt-lg">
      <div :key="index" v-for="(repayment, index) in repayments" class="repayment-item">
        <q-card class="q-mx-xs bg-light-blue-7">
          <q-card-section class="justify-center repayment-title">
            <div class="text-h6">Repayment {{ index + 1 }}</div>
          </q-card-section>
          <q-separator />
          <q-card-section class="text-center bg-blue-1">
            <div class="text-h6">{{ repayment.date | date }}</div>
            <div class="text-subtitle2">£{{ repayment.total}}</div>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pay-plan-repayments',
  props: {
    repayments: {
      type: Array
    }
  },
  computed: {
    maxWidthDynamic () {
      return {
        'max-width': this.repayments.length <= 4
          ? '600px'
          : (parseInt(this.repayments.length / 1.25) * 100).toString() + 'px'
      }
    }
  }
}
</script>

<style scoped>

</style>
