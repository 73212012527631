<template>
  <div>
    <q-item class="no-padding">
      <q-item-section>
        <q-icon :color="paidColor" :name="paidIcon" size="lg" />
      </q-item-section>
      <q-item-section >
        <span class="text-subtitle1">{{ repayment.get('payment_date') | date}}</span>
      </q-item-section>
      <q-item-section avatar>
        <span class="text-h6 text-bold text-light-blue-7"> - {{repayment.get('amount') | currency}}</span>
      </q-item-section>
    </q-item>
  </div>
</template>

<script>
export default {
  name: 'repayment',
  props: {
    repayment: {
      type: Object,
      require: true
    }
  },
  computed: {
    paidIcon () {
      return this.repayment.get('is_paid') === 1 ? 'check_circle' : 'cancel'
    },
    paidColor () {
      return this.repayment.get('is_paid') === 1 ? 'positive' : 'negative'
    }
  }
}
</script>

<style scoped>
</style>
