<template>
  <q-card class="text-center rounded-borders" style="border: green 3px solid">
    <q-card-section>
      <img src="../assets/cp_success.svg">
      <br>
      <span class="text-h6">Payment Successful</span>
      <br>
      <span class="text-subtitle2 text-weight-light">
         Thank you! Your payment of {{ amount | currency }} has been received.
        <span v-if="earlySettlement"> Your loan will be closed shortly.</span>
        </span>
    </q-card-section>
    <q-card-actions align="center">
      <q-btn label="OK" rounded class="bgBtnLiner text-white" @click="$emit('ok')" />
    </q-card-actions>
  </q-card>
</template>

<script>
export default {
  name: 'PaymentSuccessful',
  props: {
    amount: {
      required: true
    },
    earlySettlement: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
