<template>
  <q-card class="my-card">
    <form @submit.prevent="fixCard">
      <q-card-section class="text-center">
        <br>
        <q-icon name="credit_card" color="light-blue-5" size="md"/>
        <br>
        <span class="text-h6"> Fix Card</span>
        <br>
      </q-card-section>

      <br>
      <q-card-section>

        <q-field>
            <q-input type="text" :value="card.type()" label="Card Type" outlined readonly />
        </q-field>

        <br>
        <q-field>
          <q-input type="text" :value="'****' + card.lastDigits()" label="Card Number" outlined readonly>
            <template v-slot:append>
              <q-icon name="credit_card" class="cursor-pointer" />
            </template>
          </q-input>
        </q-field>
        <br>
        <div class="row">
          <q-year-month class="col-xs-8" v-model="$v.form.expiry.$model"/>
          <div class="col-xs-1"></div>
          <q-field class="col-xs-3">
            <q-input type="text" v-model.trim="$v.form.cvv.$model" label="CVV" outlined/>
          </q-field>
        </div>
        <span class="text-red"
              v-if="submitted && $v.form.cvv.$invalid">Please enter a 3 digits number</span>
        <span class="text-red"
              v-if="submitted && !$v.form.expiry.required"><br> Please select an expiry date</span>

        <span class="text-red"
              v-else-if="submitted && !$v.form.expiry.validExpiry">Please select an expiry date greater than this month</span>

        <q-field borderless>
          <q-item class="no-padding" tag="label">
            <q-item-section avatar>
              <q-checkbox :value="card.isDefault()" size="lg" color="light-blue-5" readonly/>
            </q-item-section>
            <q-item-section>
              <q-item-label class="text-light-blue-5 text-subtitle2">Use as primary payment method</q-item-label>
            </q-item-section>
          </q-item>
        </q-field>

      </q-card-section>
      <q-card-actions class="row full-width">
        <q-btn rounded class="col text-light-blue-7 outlineColor" outline label="Cancel" @click="resetForm" v-close-popup/>
        <q-btn class="col bgBtnLiner text-white" rounded type="submit" label="Done"/>
      </q-card-actions>
      <br>
    </form>
  </q-card>
</template>

<script>
import { mapGetters } from 'vuex'

import { required, minLength, maxLength, numeric } from 'vuelidate/lib/validators'
import QYearMonth from './QYearMonth'
import moment from 'moment'

const validExpiry = (value) => !value || value >= (moment().date(1).startOf('day'))

export default {
  name: 'FixCreditCard',
  components: {
    QYearMonth
  },
  data () {
    return {
      submitted: false,
      form: {
        cvv: '',
        expiry: '',
        id: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      card: 'cards/defaultCard'
    })
  },
  methods: {
    async fixCard () {
      if (this.$v.form.$invalid) {
        this.submitted = true
        return false
      }

      this.form.id = this.card.id()

      let success = await this.$dispatch({
        action: 'cards/fixCard',
        params: this.form,
        message: 'Fixing Card. This might take a while...',
        successMessage: 'Credit Card was fixed'
      })

      if (success) {
        this.$emit('success', true)
      }
    },
    resetForm () {
      this.$v.form.$reset()
    }
  },
  validations: {
    form: {
      cvv: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(3),
        numeric
      },
      expiry: {
        required,
        validExpiry
      }
    }
  }
}
</script>

<style scoped>

</style>
