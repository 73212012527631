<template>
    <div>
      <span :class="$q.screen.lg ? 'text-body1' : 'text-body2'">
            You're loan has been paid off! What would you like to do next?
            <br />
         <q-card-actions align="center">
              <q-btn  rounded text-color="light-blue-7" label="Review Loans" @click="navTo('loans')"/>
              <q-btn  rounded class="bgBtnLiner text-white" icon="send" label="Re-apply" @click="openModal = true"/>
              <q-btn flat label="Exit"  @click="logout"/>
          </q-card-actions>
      </span>

      <q-dialog :value="openModal" :full-width="$q.screen.xs"  @hide="openModal = false">
        <q-card class="my-card">
            <q-card-section class="text-center">
              <br>
              <q-icon name="cp_coins" color="light-blue-5" size="md"/>
              <br>
              <span class="text-h6"> Reapply Now</span>
              <br>
            </q-card-section>

            <q-card-section>
              <div class="text-subtitle1 q-pr-lg q-pl-lg">
                <b>Warning:</b>
                Late repayments can cause you serious money problems. For help, go to
                <a href="moneyhelper.org.uk"> moneyhelper.org.uk </a>
                <br>
                <b>Representative Example:</b>
                Borrow £400 for 4 months: 3 monthly repayments of £156.09 followed by a final repayment of £156.07.
                <br>
                Total repayment £624.34. Interest rate p.a. (fixed) 288.35%.
                Representative APR 1,267.9%.
                <a href="https://www.allthelenders.org.uk/#!months%7C1%7C4%7C400%7C0%7C%7B%7D%7C413640"> Compare </a> Moneyboat loans.
              </div>
            </q-card-section>

            <q-card-actions align="center">
              <q-btn @click="openModal = false" class="q-mr-xl" rounded label="Close"/>
              <q-btn @click="submit" class="bgBtnLiner text-white" rounded type="submit" label="I understand"/>
            </q-card-actions>
        </q-card>
      </q-dialog>
    </div>
</template>

<script>
export default {
  name: 're-apply-app',
  data () {
    return {
      openModal: false
    }
  },
  computed: {
    token () {
      return localStorage.getItem('access_token').split('__q_strn|')[1]
    }
  },
  methods: {
    async submit () {
      location.href = `${env('APPLY_URL')}?ctoken=${this.token}&utm_source=741`
    },
    navTo (name) {
      if (this.$route.name !== name) {
        this.$router.push({ name })
      }
    },
    logout () {
      this.$oauth.logout()
      window.dataLayer.push({ event: 'logged_out' })
      this.$router.replace({ name: 'login' })
    }
  }
}
</script>

<style scoped>
  .emailLink {
    color: #1c9edb;
    font-weight: 700;
    text-decoration: underline;
  }

  @media (min-width:1440px) and (max-width:1490px) {
  >>> button {
    font-size:12px;
    }
  }
</style>
