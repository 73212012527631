<template>
  <q-card class="my-card" v-if="openLoan">
    <q-item>
      <q-item-section side class="q-pr-sm">
        <q-icon name="cp_balance"/>
      </q-item-section>
      <q-item-section>
        <q-item-label class="text-subtitle1 text-bold">Balance</q-item-label>
      </q-item-section>
    </q-item>
    <q-separator/>

    <q-card-section>
      <q-item>
        <q-item-section avatar>
          <span class="text-weight-thin my-help"> Remaining Total</span>
          <span class="text-h5 text-bold text-light-blue-5">{{this.openLoan.currentBalance() | currency}}</span>
        </q-item-section>
        <q-item-section class="items-end">
          <span class="text-weight-thin my-help"> Loan Payoff Date</span>
          <span class="text-subtitle2 text-bold">{{this.openLoan.lastRepayment().get('payment_date') | date}}</span>
        </q-item-section>
      </q-item>
    </q-card-section>
  </q-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'balance',
  computed: {
    ...mapGetters({
      openLoan: 'loans/openLoan'
    })
  }
}
</script>

<style scoped>

</style>
