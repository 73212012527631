<template>
  <div class="col-sm-12 row" v-if="currentAlerts.length > 0">
    <div :key="i" class="col-12" :class="$q.screen.lg ? 'q-pt-md' : ''" v-for="(alert, i) in currentAlerts">
      <Alert v-bind="alert"/>
    </div>
  </div>
</template>

<script>
import Alert from './Alert'
import { mapGetters } from 'vuex'
import filters from '../mixins/filters'
import ReApplyApp from './ReApplyApp'

export default {
  name: 'DashboardAlerts',
  mixins: [filters],
  components: {
    Alert
  },
  data () {
    return {
      env: env,
      alerts: [
        {
          name: 'overdue',
          iconClass: 'red-7',
          borderStyle: 'border-left: 5px #F55E5E solid;',
          bgClass: 'bg-red-1',
          iconName: 'report_problem',
          text: 'Your payment is <b>overdue</b> and your balance is currently increasing by <b>{{XXX}}</b> per day.'
        },
        {
          name: 'loanClosed',
          iconClass: 'green-7',
          borderStyle: 'border-left: 5px #43a047 solid;',
          bgClass: 'bg-green-1',
          iconName: 'mood',
          text: '',
          component: ReApplyApp
        },
        {
          name: 'pendingUpdate',
          iconClass: 'orange-6',
          borderStyle: 'border-left: 5px orange solid;',
          bgClass: 'bg-orange-1',
          iconName: 'mood',
          text: 'Loan is awaiting an update. Please check back in a few minutes'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      openLoan: 'loans/openLoan'
    }),
    currentAlerts () {
      let current = this.alerts.filter(alert => {
        switch (alert.name) {
          case 'overdue':
            return this.openLoan && this.openLoan.accruesInterest()
          case 'loanClosed':
            return !this.openLoan
          case 'pendingUpdate':
            return this.openLoan && this.openLoan.params.pending_update
          default:
            return true
        }
      })

      return current.map(alert => {
        switch (alert.name) {
          case 'overdue':
            alert.text = alert.text.replace('{{XXX}}', this.currency(this.openLoan.dailyInterest()))
            return alert
          default:
            return alert
        }
      })
    }
  },
  mounted () {
    if (this.currentAlerts.length === 0) {
      this.$emit('noAlerts')
    }
  }
}
</script>

<style scoped>

</style>
