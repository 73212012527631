<template>
  <q-card  :class="bgClass" :style="borderStyle" >
    <q-card-section>
      <q-item class="no-padding">
        <q-item-section side >
          <q-icon size="md" :color="iconClass" :name="iconName"/>
        </q-item-section>
        <q-item-section >
          <component v-if="component" :is="component"/>
          <span v-else :class="$q.screen.lg ? 'text-body1' : 'text-body2'" v-html="text"></span>
        </q-item-section>
      </q-item>
    </q-card-section>
  </q-card>
</template>

<script>

export default {
  name: 'alert',
  props: {
    iconName: {
      type: String,
      required: true
    },
    bgClass: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    iconClass: {
      type: String,
      required: true
    },
    borderStyle: {
      type: String,
      required: true
    },
    component: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>
