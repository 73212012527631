<template>
  <q-card v-if="cards.length" class="my-card text-center">
    <q-card-section class="bgMyLiner text-white">
      <q-item>
        <q-item-section class="absolute-top-left">
          <q-btn flat label="Cancel" v-close-popup size="sm"/>
        </q-item-section>
        <q-item-section class="absolute-top-right">
          <q-btn flat type="submit" label="Done" size="sm"/>
        </q-item-section>
      </q-item>
    </q-card-section>
    <q-card-section>
      <br>
      <br>
      <q-icon name="cp_card" size="lg"></q-icon>
      <br>
      <span class="text-h6">Pay One Payment</span>
      <br>
      <span class="text-subtitle1 text-weight-thin"> Loan #{{openLoan.get('ref_number')}}</span>
      <br>
    </q-card-section>
    <q-separator/>
    <form @submit.prevent="pay">
      <q-card-section>
        <q-field borderless>
          <q-input :value="customer.fullName()" label="Customer Name" borderless readonly/>
        </q-field>
        <q-field borderless>
          <q-input :value="form.date" label="Payment Date" borderless readonly/>
        </q-field>
        <q-field borderless>
          <q-input v-model="form.amount" label="Amount" type="number" ref="amount"
                   :rules="[val => !!val || 'Field is required',
                            val => val > 0 || 'Amount can be greater then 0',
                            val => val <= parseFloat(openLoan.earlySettlementAmount()) || 'Amount can be smaller then ' + openLoan.earlySettlementAmount(),]" />
        </q-field>
        <q-field borderless class="card" icon="lock">
          <q-select v-model="form.card" :options="cards" label="Credit Card" ref="card" :rules="[val => !!val || 'Field is required']" />
        </q-field>
        <q-field borderless class="no-padding">
          <q-btn flat label="+ add card" class="text-light-blue-5" @click="$emit('goTo', 'AddCreditCard')"/>
        </q-field>
      </q-card-section>

      <q-card-actions>
        <q-btn flat size="lg" class="bgMyLiner text-white full-width" rounded type="submit" label="Make Payment"/>
      </q-card-actions>
    </form>
    <q-dialog :value="!!currentNotify" small @hide="currentNotify = null">
      <component v-if="currentNotify" :is="currentNotify" @ok="$emit('success')" @again="pay" :amount="form.amount"/>
    </q-dialog>
  </q-card>
</template>

<script>
import cards from '../mixins/cards'
import PaymentFailed from './PaymentFailed'
import PaymentSuccessful from './PaymentSuccessful'
import moment from 'moment'

export default {
  name: 'PayOnePayment',
  mixins: [cards],
  components: {
    PaymentFailed,
    PaymentSuccessful
  },
  data () {
    return {
      currentNotify: null,
      form: {
        card: '',
        amount: '',
        date: moment().format('DD-MM-YYYY')
      }
    }
  },
  methods: {
    async pay () {
      this.$refs.amount.validate()
      this.$refs.card.validate()

      if (this.$refs.amount.hasError || this.$refs.card.hasError) {
        return
      }
      var success = await this.$dispatch({
        action: 'loans/payPartialRepayment',
        params: {
          amount: this.form.amount,
          card_id: this.form.card.value,
          repayment_id: this.firstRepayment.get('id')
        },
        message: 'Processing Payment. This might take a while...'
      })

      if (success) {
        window.dataLayer.push({ event: 'paid_partial_payment' })
      }

      this.currentNotify = success ? 'PaymentSuccessful' : 'PaymentFailed'
    }
  },
  computed: {
    openLoan () {
      return this.$store.state.loans.loans.find(loan => loan.isOpen())
    },
    customer () {
      return this.$store.state.customer.customer
    },
    firstRepayment () {
      return this.openLoan.firstRepayment()
    }
  }
}
</script>

<style scoped>
</style>
