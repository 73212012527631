<template>
  <q-card class="my-card">

    <q-card-section>
      <q-list bordered separator>
        <q-item id="btn_request_callback cursor-pointer" clickable @click="openModal = true" class="q-pl-lg">
          <q-item-section side>
            <q-icon color="success" name="cp_phone"/>
          </q-item-section>
          <q-item-section>
                <span class="text-subtitle1 text-bold">
                  Request callback
                </span>
            <span class="text-weight-thin">
                    9:00 am - 5:00 pm | Monday to Friday
                </span>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card-section>

    <q-dialog v-model="openModal">
      <q-card class="text-center rounded-borders q-ma-lg-lg q-pa-lg-lg" style="border: cornflowerblue 2px solid">
        <q-card-section>
          <q-icon name="cp_phone" color="success" style="font-size: 50px"/>
          <br>
          <br>
          <span class="text-h6">When is the best time to try and contact you?</span>
          <br>
          <br>
          <q-select ref="channel" map-options emit-value :options="channelOptions" label="Choose Channel" filled
                    v-model="form.channel">
            <template v-slot:prepend>
              <q-icon name="chat"/>
            </template>
          </q-select>
          <br>
          <div v-if="form.channel==='phone'">
            <q-select ref="date" :options="dateOptions" label="Choose Date" filled v-model="form.date"
                      :rules="[val => !!val || 'Field is required']">
              <template v-slot:prepend>
                <q-icon name="event"/>
              </template>
            </q-select>
            <q-select ref="time" :options="currentTimeOptions" label="Choose Time" filled v-model="form.time"
                      :rules="[val => !!val || 'Field is required']">
              <template v-slot:prepend>
                <q-icon name="schedule"/>
              </template>
            </q-select>
          </div>
          <q-input label="How can we help?" v-model="form.desc" filled autogrow>
            <template v-slot:prepend>
              <q-icon name="description"/>
            </template>
          </q-input>
        </q-card-section>
        <q-card-actions align="center">
          <q-btn label="Cancel" style="min-width: 100px" rounded @click="onCancel"/>
          <q-btn label="Submit" style="min-width: 100px" rounded class="bgBtnLiner text-white" @click="sendCallback"/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog small :value="sent">
      <q-card class="text-center rounded-borders" style="border: cornflowerblue 2px solid">
        <q-card-section>
          <img src="../assets/cp_agent.svg">
          <br>
          <span class="text-h6">Thank You! We will be in touch</span>
          <br>
          <span class="text-subtitle2 text-weight-light">
            <span v-if="form.channel==='phone'">
              Thank you for requesting a call back to discuss your account. One of our agents will endeavor to call you at the requested time. However, if we are unable to reach you at that time, we will call you as soon as possible.
            </span>
            <span v-else>
              We will get back to you as soon as possible.
            </span>
          </span>
        </q-card-section>
        <q-card-actions align="center">
          <q-btn label="OK" style="min-width: 100px" rounded class="bgBtnLiner text-white" @click="close"/>
        </q-card-actions>
      </q-card>
    </q-dialog>

  </q-card>
</template>

<script>
import moment from 'moment'
import EventBus from '../utils/even-bus'

export default {
  name: 'RequestCallback',
  mounted () {
    EventBus.$on('call-back', (data) => {
      if (this.openModal) {
        return
      }
      this.openModal = true
      this.form.desc = data
    })
  },
  data () {
    return {
      sent: false,
      openModal: false,
      form: {
        date: '',
        time: '',
        channel: 'phone',
        desc: ''
      },
      timeOptions: [
        {
          startTime: 8,
          title: '8:00 - 10:00'
        },
        {
          startTime: 10,
          title: '10:00 - 12:00'
        },
        {
          startTime: 12,
          title: '12:00 - 14:00'
        },
        {
          startTime: 14,
          title: '14:00 - 17:00'
        }
      ],
      channelOptions: [
        {
          label: 'Phone',
          value: 'phone'
        },
        {
          label: 'Email',
          value: 'email'
        },
        {
          label: 'SMS',
          value: 'sms'
        }
      ]
    }
  },
  methods: {
    onCancel () {
      this.openModal = false
      this.resetForm()
    },
    async sendCallback () {
      if (this.form.channel === 'phone') {
        this.$refs.date.validate()
        this.$refs.time.validate()
        if (this.$refs.date.hasError || this.$refs.time.hasError) {
          return
        }
        this.form.date = moment(this.form.date, 'DD MMMM').format('YYYY-MM-DD')
      }

      this.sent = await this.$dispatch({
        action: 'customer/requestCallback',
        message: 'Requesting callback...',
        successMessage: 'Callback request sent. An agent will be in touch shortly',
        params: this.form
      })

      if (this.sent) {
        this.openModal = false
        window.dataLayer.push({ event: 'requested_callback' })
      }
    },
    close () {
      this.sent = false
      this.resetForm()
    },
    isHoliday (current) {
      return this.holidays && this.holidays.includes(current.format('YYYY-MM-DD'))
    },
    resetForm () {
      this.form = {
        date: '',
        time: '',
        desc: '',
        channel: 'phone'
      }
    }
  },
  computed: {
    holidays () {
      return this.$store.state.holidays.holidays
    },
    dateOptions () {
      let dates = []
      let current = moment()

      if (current.hour() > 14) {
        current = current.add(1, 'days')
      }

      for (let i = 0; i < 14; i++) {
        if (current.day() > 0 && current.day() < 6 && !this.isHoliday(current)) {
          dates.push(current.format('DD MMMM'))
        }
        current = current.add(1, 'days')
      }
      return dates
    },
    currentTimeOptions () {
      if (!moment().isSame(moment(this.form.date, 'DD MMMM'), 'days')) {
        return this.timeOptions.map(t => t.title)
      }
      return this.timeOptions
        .filter(t => moment().hour() < t.startTime)
        .map(t => t.title)
    }
  }
}
</script>

<style>
</style>
