<template>
  <q-card class="my-card">
      <q-card-section class="text-center">
        <br>
        <br>
        <img src="../assets/cp_card.svg">
        <br>
        <span class="text-h6">Self Service Loan Centre</span>
        <br>
        <span class="text-subtitle1 text-weight-thin"> Loan #{{openLoan.get('ref_number')}}</span>
        <br>
      </q-card-section>

    <q-card-section v-if="openLoan.inLegalStatus()">
       <span class="text-subtitle1 text-center">
        As there are currently outstanding legal proceedings associated with your account,
        you can not set up a new payment plan at the present time.
        Instead, please <a class="link cursor-pointer" @click="callback">contact us</a> to clear this issue up as soon as possible.
        You can however pay off your entire debt by clicking <a class="link cursor-pointer" @click="$emit('goTo', 'PayEarlySettlement')"> here </a>.
       </span>
    </q-card-section>

    <div v-else>
      <PayPlanForm @success="$emit('success')" @failure="$emit('failure')" @goTo="$emit('goTo', 'ArrangementCallback')" :loan="openLoan"/>
     </div>

    <q-card-actions align="center">
      <q-btn rounded class="text-light-blue-7 outlineColor q-pr-lg q-pl-lg" outline label="Cancel" v-close-popup/>
    </q-card-actions>
  </q-card>
</template>

<script>
import EventBus from '../utils/even-bus'
import PayPlanForm from './PayPlanForm'

export default {
  name: 'payment-plan-presets',
  data () {
    return {
      confirmed: false
    }
  },
  computed: {
    openLoan () {
      return this.$store.state.loans.loans.find(loan => loan.isOpen())
    }
  },
  components: {
    PayPlanForm
  },
  methods: {
    callback () {
      EventBus.$emit('call-back', 'I do want change my payment plan,\n But I am in legal status.')
      this.$emit('failure')
    }
  }
}
</script>

<style scoped>
  .repayment-item {
    /*flex: 1;*/
    padding: 10px 0;
  }
  .repayment-title {
    font-size: 13px;
    color: white;
  }
  .link {
    color: #1c9edb;
    font-weight: 700;
    text-decoration: underline;
  }

</style>
