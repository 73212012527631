<template>
  <div class="full-width">
    <q-select label="Payment Frequency" :options="freqs" v-model="form.frequency" @input="onInput" emit-value map-options/>
    <q-date-input label="Date" v-model="form.firstDate" v-if="needFirstDate" @input="onInput"/>
    <q-select :options="days" label="Day" v-model="form.day" v-if="form.frequency==='specified'" @input="onInput"/>
  </div>
</template>

<script>
import QDateInput from './QDateInput'
export default {
  name: 'pay-frequency-plan',
  data () {
    return {
      freqs: [
        {
          value: '1w',
          label: 'Weekly',
          id: 1
        },
        {
          value: '2w',
          label: 'Bi Weekly',
          id: 2
        },
        {
          value: '4w',
          label: 'Four Weekly',
          id: 3
        },
        {
          value: 'last_working',
          label: 'Last working day of the month',
          id: 4
        },
        {
          value: 'second_from_last_working',
          label: 'Second from last working day',
          id: 5
        },
        {
          value: 'third_from_last_working',
          label: 'Third from last working day',
          id: 6
        },
        {
          value: 'fourth_from_last_working',
          label: 'Fourth from last working day',
          id: 7
        },
        {
          value: 'last_friday',
          label: 'Last Friday',
          id: 8
        },
        {
          value: 'last_thursday',
          label: 'Last Thursday',
          id: 9
        },
        {
          value: 'last_wednesday',
          label: 'Last Wednesday',
          id: 10
        },
        {
          value: 'last_tuesday',
          label: 'Last Tuesday',
          id: 11
        },
        {
          value: 'last_monday',
          label: 'Last Monday',
          id: 12
        },
        {
          value: 'specified',
          label: 'Specified',
          id: 13
        },
        {
          value: 'first_thursday',
          label: 'First Thursday',
          id: 14
        },
        {
          value: 'third_friday',
          label: 'Third Friday',
          id: 15
        },
        {
          value: 'first_wednesday',
          label: 'First Wednesday',
          id: 16
        },
        {
          value: 'first_working',
          label: 'First Working day of the month',
          id: 17
        },
        {
          value: 'first_friday',
          label: 'First Friday',
          id: 18
        },
        {
          value: 'third_thursday',
          label: 'Third Thursday',
          id: 19
        }
      ],
      form: {
        frequency: '',
        firstDate: null,
        day: null,
        deferForward: false
      }
    }
  },
  methods: {
    resetFirstDate () {
      if (!this.needFirstDate) {
        this.form.firstDate = ''
      }
    },
    onInput () {
      this.resetFirstDate()
      let data = JSON.parse(JSON.stringify(this.form))
      data.frequency = data.frequency === 'specified' ? data.day.toString() : data.frequency
      this.$emit('input', data)
    }
  },
  mounted () {
    this.form.frequency = this.freqs.find(f => f.id === this.customer.get('payment_frequency_id')).value
  },
  computed: {
    customer () {
      return this.$store.state.customer.customer
    },
    needFirstDate () {
      return ['1w', '2w', '4w'].includes(this.form.frequency)
    },
    days () {
      return window._.range(1, 32)
    }
  },
  components: {
    QDateInput
  }
}
</script>

<style scoped>

</style>
