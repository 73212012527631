<template>
  <div>
    <q-card-section>
    <span class="text-subtitle1 text-center">
      Please confirm whether the income and expenditure supplied on your Application are still valid?
    </span>
    <span class="text-body2">
      <br/> <br/>
      Deferring payment will negatively impact your credit record.
      Additional interest charge might make payment unaffordable for you.
      Make sure payment terms are affordable as missing additional payment will cause further problems.
      Deferral should not be used in cases of financial difficulty - contact us instead to discuss your account.
      <br /> <br />
      Warning: Late repayments can cause you serious money problems. For help, go to <a href="www.moneyadviceservice.org.uk">moneyadviceservice.org.uk</a>
    </span>
    </q-card-section>

    <q-card-actions align="center">
      <q-btn rounded no-caps icon="done" label="Confirm and Continue" @click="$emit('confirm')"/>
      <q-btn rounded no-caps icon="info" label="My Income and Expenditure have changed" @click="unConfirm"/>
    </q-card-actions>
  </div>
</template>

<script>
var CryptoJS = require('crypto-js')

export default {
  name: 'confirm-income',
  props: {
    amount: {
      required: true
    }
  },
  methods: {
    unConfirm () {
      var token = CryptoJS.AES.encrypt(this.amount, 'schlegermeier').toString()

      this.$router.push('income-and-exp/' + token)
    }
  }
}
</script>

<style scoped>

</style>
