<template>
  <div class="full-width">
    <q-input v-model="date" label="Date" mask="date" :rules="['date']">
      <template v-slot:append>
        <q-icon name="event" class="cursor-pointer">
          <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
            <q-date v-model="date" @input="updateValue" :options="optionsFn" />
          </q-popup-proxy>
        </q-icon>
      </template>
    </q-input>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'q-date-input',
  props: {
    min: {
      type: String,
      default: moment().subtract(70, 'years').format('YYYY/MM/DD')
    },
    max: {
      type: String,
      default: moment().add(50, 'years').format('YYYY/MM/DD')
    }
  },
  mounted () {
    this.$emit('input', this.date)
  },
  data () {
    return {
      date: moment(this.min, 'YYYY/MM/DD') > moment() ? this.min : moment().format('YYYY/MM/DD')
    }
  },
  methods: {
    updateValue () {
      this.$refs.qDateProxy.hide()
      this.$emit('input', this.date)
    },
    optionsFn (date) {
      return date >= this.min && date <= this.max
    }
  }
}
</script>

<style scoped>

</style>
