<template>
  <div>
  <q-page class="q-pl-md q-pr-md" v-if="!loading && loan">
    <!-- Mobile view-->
    <div class="lt-lg row items-center q-gutter-md">
      <RepaymentsDashboard v-if="!pendingUpdate"/>

      <Loan :loan="lastLoan" v-if="!openLoan"/>

      <q-card class="my-card">
        <DashboardAlerts />
      </q-card>

      <LoanDetailsDashboard  v-if="openLoan"/>

      <Balance v-if="openLoan && !pendingUpdate"/>

      <NextRepaymentDashboard v-if="nextRepayment && !pendingUpdate"/>

      <LoanActions v-if="canSettleEarly"/>

      <RequestCallback />

      <CardDashboard v-if="openLoan"/>
    </div>
    <!--End Mobile view-->

    <!-- Desktop view-->
    <!-- open loan view-->
    <div class="gt-md" v-if="openLoan">

      <div class="q-gutter-lg q-pl-lg">
        <div class="row">
          <div class="col-lg-4 col-sm-12 items-center">
            <LoanDetailsDashboard />
          </div>
          <div class="col-lg-4 col-sm-12 items-center" v-if="!pendingUpdate">
            <Balance />
          </div>
          <div class="col-lg-4 col-sm-12 items-center" v-if="nextRepayment && !pendingUpdate">
            <NextRepaymentDashboard />
          </div>
        </div>

        <div class="row q-pr-lg" v-if="isAlerts">
          <DashboardAlerts @noAlerts="isAlerts = false"/>
        </div>

        <div class="row">
          <div class="col-lg-4 col-sm-12 items-center" v-if="canSettleEarly">
            <LoanActions/>
          </div>
          <div class="col-lg-4 col-sm-12 items-center">
            <CardDashboard/>
            <br>
            <RequestCallback/>
          </div>
          <div class="col-lg-4 col-sm-12 items-center">
            <RepaymentsDashboard v-if="!pendingUpdate"/>
          </div>
        </div>
      </div>
    </div>
    <!-- End open loan view-->

    <!-- closed loan view-->
    <div class="gt-md" v-else>
      <div class="q-pa-lg q-gutter-lg">
        <div class="row">
          <div class="col-lg-4 col-sm-12 items-center">
            <DashboardAlerts @noAlerts="isAlerts = false" class="q-pr-lg"/>
            <br>
            <RequestCallback/>
            <br>
             <Loan :loan="lastLoan" />
           </div>

          <div class="col-lg-4 col-sm-12 items-center">
            <RepaymentsDashboard />
          </div>
        </div>
      </div>
    </div>
    <!--End Desktop view-->
  </q-page>

    <q-page class="q-pl-md q-pr-md" v-if="!loading && !hasLoans">
      <h5 class="no-loans">You do not have any loans with us yet.</h5>
    </q-page>
    </div>
</template>

<style>
  .mobile .my-card, .screen--xs .my-card, .screen--sm .my-card, .screen--md .my-card  {
    width: 100%;
  }

  .screen--lg .my-card, .screen--xl .my-card {
    width: 95%;

  }
  .my-help {
    font-size: 12px;
  }
</style>

<script>

import RepaymentsDashboard from '../components/RepaymentsDashboard'
import DashboardAlerts from '../components/DashboardAlerts'
import Loan from '../components/Loan'
import LoanDetailsDashboard from '../components/LoanDetailsDashboard'
import NextRepaymentDashboard from '../components/NextRepaymentDashboard'
import Balance from '../components/Balance'
import LoanActions from '../components/LoanActions'
import RequestCallback from '../components/RequestCallback'
import CardDashboard from '../components/CardDashboard'
import { mapGetters } from 'vuex'

export default {
  name: 'PageDashboard',
  components: {
    RepaymentsDashboard,
    DashboardAlerts,
    Loan,
    LoanDetailsDashboard,
    Balance,
    NextRepaymentDashboard,
    LoanActions,
    CardDashboard,
    RequestCallback
  },
  meta: {
    title: 'Dashboard'
  },
  data () {
    return {
      loading: true,
      isAlerts: true
    }
  },
  async mounted () {
    this.$dispatch([
      {
        action: 'customer/getCustomer',
        message: 'Loading Customer Data...'
      },
      {
        action: 'applications/getApplications',
        message: 'Loading Applications...'
      },
      {
        action: 'loans/getLoans',
        message: 'Loading Loans...'
      },
      {
        action: 'cards/getCards',
        message: 'Loading Cards...'
      }
    ])
  },
  computed: {
    ...mapGetters({
      openLoan: 'loans/openLoan',
      lastLoan: 'loans/lastLoan'
    }),
    hasLoans () {
      return this.$store.state.loans.loans && !!this.$store.state.loans.loans.length
    },
    repayments () {
      return this.openLoan ? this.openLoan.repayments() : []
    },
    nextRepayment () {
      return this.repayments[0]
    },
    loan () {
      return this.openLoan ? this.openLoan : this.lastLoan
    },
    canSettleEarly () {
      return this.openLoan && !this.pendingUpdate
      // this option may change in future release
      // return this.openLoan && moment(this.openLoan.lastRepayment().get('payment_date')) > moment()
    },
    pendingUpdate () {
      return this.openLoan && this.openLoan.params.pending_update
    }
  }
}
</script>

<style>
  .no-loans {
    margin-top: 100px;
  }
</style>
