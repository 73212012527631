<template>
  <q-card v-if="cards.length" class="my-card text-center">
    <form @submit.prevent="pay">
     <q-card-section>
        <br>
        <br>
        <img src="../assets/cp_card.svg">
        <br>
        <span class="text-h6">Pay Off Now</span>
        <br>
        <span class="text-subtitle1 text-weight-thin"> Loan #{{openLoan.get('ref_number')}}</span>
        <br>
      </q-card-section>
      <q-separator/>

      <q-card-section>
        <q-field borderless>
          <q-input :value="customer.fullName()" label="Customer Name" borderless readonly/>
        </q-field>
        <q-field borderless>
          <q-input :value="openLoan.earlySettlementAmount() | currency" label="Amount" borderless readonly/>
        </q-field>
        <q-field borderless class="card" icon="lock">
          <q-select v-model="form.card" :options="cards" label="Credit Card" required/>
        </q-field>
        <q-field borderless>
          <q-btn flat label="+ add card" class="text-light-blue-5" @click="$emit('goTo', 'AddCreditCard')"/>
        </q-field>
      </q-card-section>

      <q-card-actions class="row full-width">
        <q-btn rounded class="col text-light-blue-7 outlineColor" outline label="Cancel" @click="resetForm" v-close-popup/>
        <q-btn   class="col bgBtnLiner text-white" rounded type="submit"  label="Settle In Full"/>
      </q-card-actions>
      <br>
    </form>
    <q-dialog :value="!!currentNotify" small @hide="currentNotify = null">
      <component v-if="currentNotify" :is="currentNotify" early-settlement @ok="$emit('success')" :amount="this.openLoan.earlySettlementAmount()"/>
    </q-dialog>
  </q-card>
</template>

<script>
import cards from '../mixins/cards'
import PaymentFailed from './PaymentFailed'
import PaymentSuccessful from './PaymentSuccessful'

export default {
  name: 'PayEarlySettlement',
  components: {
    PaymentFailed,
    PaymentSuccessful
  },
  mixins: [cards],
  data () {
    return {
      sending: false,
      currentNotify: null,
      form: {
        card: ''
      }
    }
  },
  methods: {
    async pay () {
      if (this.sending) return
      this.sending = true
      var success = await this.$dispatch({
        action: 'loans/payEarlySettlement',
        params: {
          loan_id: this.openLoan.id(),
          card_id: this.form.card.value
        },
        message: 'Processing Payment. This might take a while...'
      })

      if (success) {
        window.dataLayer.push({ event: 'paid_early_settlement' })
      }

      this.sending = false
      this.currentNotify = success ? 'PaymentSuccessful' : 'PaymentFailed'
    },
    resetForm () {
      this.form.card = ''
    }
  },
  computed: {
    openLoan () {
      return this.$store.state.loans.loans.find(loan => loan.isOpen())
    },
    customer () {
      return this.$store.state.customer.customer
    }
  }
}
</script>

<style scoped>
  .my-header {
    height: 150px;
  }
</style>
