<template>
    <q-card>
        <q-card-section class="row items-center" v-if="isArrangement">
          Please be advised that entering into a payment arrangement may result in increasing the duration of your loan and/or increasing the cost of future monthly instalments. It will be marked as an arrangement to pay on your credit report and some lenders may take this as a sign that you are unable to keep up with your credit commitments. It might therefore affect your ability to obtain credit in the future as the arrangement marker will stay in your credit history for 6 years.
        </q-card-section>
      <q-card-section class="row items-center" v-if="deferedInterest">
                          Additional interest charge might make payment unaffordable for you.
                          <br/>
                          Make sure payment terms are affordable as missing additional payment will cause further
                          problems.
                          <br/>
                          Deferral should not be used in cases of financial difficulty - contact us instead to discuss
                          your account.
      </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="primary" v-close-popup />
          <q-btn flat label="Accept" @click="$emit('accept')" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
</template>

<script>
export default {
  name: 'ConfirmArrangement',
  props: {
    deferedInterest: {
      type: Boolean,
      required: true
    },
    isArrangement: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
