<template>
    <q-card class="text-center rounded-borders" style="border: red 3px solid">
      <q-card-section>
        <img src="../assets/cp_failed.svg">
        <br>
        <span class="text-h6">Payment attempt failed!</span>
        <br>
        <span class="text-subtitle2 text-weight-light">
          Please contact us on 0203 818 7470 or by email collections@moneyboat.co.uk to discuss your options.
        </span>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn label="Try again" rounded class="bgBtnLiner text-white" @click="$emit('again')" />
      </q-card-actions>
    </q-card>
</template>

<script>
export default {
  name: 'PaymentFailed'
}
</script>

<style scoped>

</style>
