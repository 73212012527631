<template>
  <q-card class="my-card" v-if="nextRepayment">
    <q-item>
      <q-item-section  side class="q-pr-sm">
        <q-icon name="cp_orange_card"/>
      </q-item-section>
      <q-item-section>
        <q-item-label class="text-subtitle1 text-bold">Repayment</q-item-label>
      </q-item-section>
    </q-item>
    <q-separator/>

    <q-card-section>
      <q-item clickable @click="openPaymentPlanPresetsAction">
        <q-item-section avatar>
          <span class="text-weight-thin my-help"> Next Payment Amount</span>
          <span class="text-h5 text-bold text-light-blue-5">{{nextRepayment.amount() | currency}}</span>
        </q-item-section>
        <q-item-section class="items-end">
          <span class="text-weight-thin my-help"> Payment Date</span>
          <span v-if="nextRepayment.isOverdue()" class="text-subtitle2 text-bold text-red-7">Overdue</span>
          <span v-else class="text-subtitle2 text-bold" >{{nextRepayment.paymentDate() | date}}</span>
        </q-item-section>
      </q-item>
      <send-repayments-schedule/>
    </q-card-section>
  </q-card>
</template>

<script>
import SendRepaymentsSchedule from './SendRepaymentsSchedule'
import EventBus from '../utils/even-bus'
import { mapGetters } from 'vuex'
export default {
  name: 'next-repayment-dashboard',
  components: {
    SendRepaymentsSchedule
  },
  computed: {
    ...mapGetters({
      openLoan: 'loans/openLoan',
      lastLoan: 'loans/lastLoan'
    }),
    loan () {
      return this.openLoan ? this.openLoan : this.lastLoan
    },
    nextRepayment () {
      return this.loan.firstRepayment()
    }
  },
  methods: {
    openPaymentPlanPresetsAction () {
      EventBus.$emit('open-action', 'PaymentPlanPresets')
    }
  }
}
</script>

<style scoped>

</style>
