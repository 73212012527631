<template>
    <q-card class="my-card">
      <q-card-section>
        <q-item>
          <q-item-section>
            <span class="text-h6">Repayment Progress</span>
          </q-item-section>
          <q-item-section avatar>
            <span  class="text-h5 text-light-blue-5 text-bold justify-end" >{{progress}}%</span>
          </q-item-section>
        </q-item>

      </q-card-section>
      <q-card-section class="q-pa-lg center " style="width: 280px">
        <div class="barOverflow">
          <RepaymentProgress :diameter="280"
                             startColor="#3CCDEF"
                             stopColor="#3CCDEF"
                             innerStrokeColor="#EDF2F5"
                             :strokeWidth="15"
                             :completed-steps="paidRepayments.length"
                             :total-steps="repayments.length"
                             :hiddenPercent="25">
            <div class="bar-info text-center">
              <q-icon name="cp_card" size="lg"></q-icon>
              <br>
              <br>
              You've got
              <br>
              <span class="text-h6 text-bold">
                        {{unpaidRepayments.length }}  payments remaining
                        </span>
              <br>
              <span>of {{repayments.length}}</span>
            </div>
          </RepaymentProgress>
        </div>

      </q-card-section>

      <q-card-section>
        <q-item>
          <q-item-section>
              <q-btn class="my-border" flat @click="currentRepayments = 'paid-repayments'">
                <span class="dot bg-light-blue-5"></span>
                <span class="text-capitalize">&nbsp;&nbsp; Paid</span>
              </q-btn>
          </q-item-section>
          <q-item-section avatar>
            <q-btn class="my-border" flat @click="currentRepayments = 'remaining-repayments'">
                <span class="dot bg-grey-4"></span>
                <span class="text-capitalize">&nbsp;&nbsp; Remaining</span>
            </q-btn>
          </q-item-section>
        </q-item>
      </q-card-section>

      <q-dialog :value="!!currentRepayments" :full-width="$q.screen.xs"  @hide="currentRepayments = null">
        <component v-if="currentRepayments" :is="'Repayments'" v-bind="actionProps"/>
      </q-dialog>
    </q-card>
</template>

<script>
import { QCard, QCardSection } from 'quasar'
import { mapGetters } from 'vuex'
import 'vue-progress-path/dist/vue-progress-path.css'
import RepaymentProgress from '../components/RepaymentProgress'
import Repayments from './Repayments'

export default {
  components: { QCard, QCardSection, RepaymentProgress, Repayments },
  data () {
    return {
      currentRepayments: null
    }
  },
  computed: {
    ...mapGetters({
      openLoan: 'loans/openLoan',
      lastLoan: 'loans/lastLoan'
    }),
    loan () {
      return this.openLoan ? this.openLoan : this.lastLoan
    },
    paidRepayments () {
      return this.loan.paidRepayments()
    },
    repayments () {
      return this.loan.repayments()
    },
    unpaidRepayments () {
      return this.loan.unpaidRepayments()
    },
    progress () {
      return ((100 / this.repayments.length) * this.paidRepayments.length).toFixed(0)
    },
    actionProps () {
      return { type: this.currentRepayments }
    }
  },
  methods: {
    navTo (name) {
      if (this.$route.name !== name) {
        this.$router.replace({ name })
      }
    }
  }
}
</script>

<style scoped>
  .center {
    margin: auto;
    padding-left: 0;
  }
  .barOverflow{
    position: relative;
    overflow: hidden;
    width: 280px; height: 220px;
  }
  .bar-info {
    position: absolute;
    margin-top: -10px;
  }
  .dot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
  }
  .my-border {
    border: #E1E1E1 solid 1px;
  }
</style>
