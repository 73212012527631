<template>
      <q-card class="my-card">
        <q-card-section>
          <q-list bordered separator>
            <q-item v-if="defaultCard">
              <q-item-section avatar>
                <q-icon color="success" :name="defaultCard.typeIcon()" size="lg" />
              </q-item-section>
              <q-item-section>
              <span class="text-bold">
                {{defaultCard.type()}} **** {{defaultCard.get('last_four')}}
              </span>
                <span class="text-weight-thin">
                  Expires {{expiryCard}}
              </span>
              </q-item-section>
              <q-item-section avatar>
                <q-icon v-if="cardNotValid" color="red" name="report_problem">
                  <q-tooltip v-if="defaultCard" content-class="bg-red-1 text-black text-justify" content-style="width: 200px; font-size: 12px"
                             anchor="center left" self="center right" :offset="[10, 10]">
                    Your debit card ending in {{defaultCard.get('last_four')}} has expired. Please update your card details.
                  </q-tooltip>
                </q-icon>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section avater>
                Primary
              </q-item-section>
              <q-item-section avater>
                <span v-if="cardNotValid" class="self-end text-blue" id="btn_fix_card" clickable @click="currentAction = 'FixCreditCard'" >Fix</span>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>

        <q-card-section>
          <q-list bordered separator>
            <q-item id="btn_new_card_dash" clickable @click="currentAction = 'AddCreditCard'">
              <q-item-section class="full-width text-light-blue-5 text-center addCardBtn">
                + Add Card
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>
        <q-dialog :value="!!currentAction" persistent  :full-width="$q.screen.xs" :full-height="$q.screen.xs" @hide="currentAction = null">
          <component v-if="currentAction" :is="currentAction" @failure="onFailure" @goTo="goToComponent" @success="onSuccess" />
        </q-dialog>
      </q-card>
</template>

<script>

import AddCreditCard from './AddCreditCard'
import FixCreditCard from './FixCreditCard'
import moment from 'moment'

export default {
  name: 'card-dashboard',
  components: {
    AddCreditCard,
    FixCreditCard
  },
  data () {
    return {
      currentAction: null
    }
  },
  methods: {
    onFailure () {
      this.currentAction = null
    },
    onSuccess () {
      this.currentAction = null
      this.$dispatch([
        {
          action: 'cards/getCards',
          message: 'Refreshing Cards...',
          params: true
        }
      ])
    },
    goToComponent (e) {
      this.currentAction = e
    },
    hasOpenLoan () {
      return !!this.openLoan
    }
  },
  computed: {
    cards () {
      return this.$store.state.cards.cards
    },
    defaultCard () {
      return this.cards ? this.cards.find(card => card.isDefault()) : null
    },
    expiryCard () {
      return this.defaultCard ? moment(this.defaultCard.get('expiry')).format('MM/YY') : null
    },
    cardNotValid () {
      if (!this.defaultCard) {
        return false
      }

      if (this.hasOpenLoan()) {
        let nextRepaymentDate = moment(this.openLoan.firstRepayment().get('payment_date'))
        return moment(nextRepaymentDate).isAfter(moment(this.defaultCard.get('expiry')))
      }

      return moment().isAfter(moment(this.defaultCard.get('expiry')))
    },
    loans () {
      return this.$store.state.loans.loans
    },
    openLoan () {
      return this.loans ? this.loans.find(loan => loan.isOpen()) : null
    }
  }
}
</script>

<style scoped>
.addCardBtn {
  height: 105px;
}
</style>
