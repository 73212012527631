<template>
     <q-item>
        <q-item-section>
          <q-btn class="bgBtnLiner text-white" @click="sendRepaymentSchedule()" rounded>Email My Current Repayment Schedule</q-btn>
        </q-item-section>
      </q-item>
</template>

<script>
export default {
  name: 'SendRepaymentsSchedule',
  data () {
    return {
      sent: false,
      openModal: false
    }
  },
  methods: {
    async sendRepaymentSchedule () {
      this.sent = await this.$dispatch({
        action: 'loans/sendRepayments',
        message: 'We are processing your request...',
        successMessage: 'Please check your email for your repayment schedule.'
      })

      if (this.sent) {
        this.openModal = true
      }
    }
  }
}
</script>

<style scoped>

</style>
