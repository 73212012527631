<template>
  <q-card class="my-card" v-if="loan">
    <q-item>
      <q-item-section side class="q-pr-sm">
        <q-icon name="cp_paper"/>
      </q-item-section>
      <q-item-section>
        <q-item-label class="text-subtitle1 text-bold"> Loan Details</q-item-label>
      </q-item-section>
      <q-item-section avatar>
        <a @click="navTo('loans')">
              <q-icon name="cp_coins" size="sm" />
        </a>
      </q-item-section>
    </q-item>
    <q-separator/>

    <q-card-section>
      <q-item>
        <q-item-section avatar>
          <span class="text-weight-thin my-help"> Original Loan Amount</span>
          <span class="text-h5 text-bold text-light-blue-5">{{this.loan.get('amount') | currency}}</span>
        </q-item-section>
        <q-item-section class="items-end">
          <span class="text-weight-thin my-help"> Loan Date</span>
          <span class="text-subtitle2 text-bold">{{this.loan.get('loan_date') | date}}</span>
        </q-item-section>
      </q-item>
    </q-card-section>
  </q-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'loan-details-dashboard',
  computed: {
    ...mapGetters({
      loan: 'loans/openLoan'
    })
  },
  methods: {
    navTo (name) {
      if (this.$route.name !== name) {
        this.$router.replace({ name })
      }
    }
  }
}
</script>

<style scoped>

</style>
