<template>
  <div>
    <div class="q-pt-md">
      <div class="text-subtitle1 text-bold">
        Please enter your requested payment plan details below.
      </div>
      <div class="text-body5">
        We will endeavour to call you at your requested time.
      </div>
      <q-form @submit="onSubmit">
        <q-card-section>
          <q-field borderless>
            <q-checkbox v-model="form.is_long_term" label="Is this a long term issue?"/>
          </q-field>

          <q-field borderless>
            <q-checkbox v-model="form.can_payment_today" label="Can you make a token payment today?"/>
          </q-field>
          <q-field borderless v-if="form.can_payment_today">
            <q-input v-model="form.can_payment_amount"
                     :rules="[val => !!val || 'Field is required']"
                     type="number"
                     label="How much?"
                     hint="Don’t worry, we won’t charge the payment until a new payment plan is in place."/>
          </q-field>
          <div class="row">
            <div class="col-xs-12 col-sm-7">
              <q-field borderless>
                <q-input v-model="form.monthly_payment_amount"
                         :rules="[val => !!val || 'Field is required']"
                         type="number"
                         label="Requested Monthly Payment Amount"/>
              </q-field>
            </div>
            <div class="col-sm-1"></div>
            <div class="col-xs-12 col-sm-4">
              <q-field borderless>
                <q-select v-model="form.instalments"
                          :options="[1,2,3,4,5,6]"
                          :rules="[val => !!val || 'Field is required']"
                          label="Instalments"/>
              </q-field>
            </div>
          </div>
        </q-card-section>

        <q-card-section>
          <div class="row">
            <div class="col-xs-12 col-sm-7">
              <q-select ref="date" :options="dateOptions" label="Choose Date" v-model="form.date"
                        :rules="[val => !!val || 'Field is required']">
                <template v-slot:prepend>
                  <q-icon name="event"/>
                </template>
              </q-select>
            </div>
            <div class="col-sm-1"></div>
            <div class="col-xs-12 col-sm-4">
              <q-select ref="time" :options="currentTimeOptions" label="Choose Time" v-model="form.time"
                        :rules="[val => !!val || 'Field is required']">
                <template v-slot:prepend>
                  <q-icon name="schedule"/>
                </template>
              </q-select>
            </div>
          </div>
          <q-input label="How can we help?" v-model="description" autogrow>
            <template v-slot:prepend>
              <q-icon name="description"/>
            </template>
          </q-input>
        </q-card-section>
        <q-card-actions align="center">
          <q-btn label="Submit" type="submit" style="min-width: 100px" rounded class="bgBtnLiner text-white"/>
        </q-card-actions>
      </q-form>
    </div>

    <q-dialog small :value="sent" persistent>
      <q-card class="text-center rounded-borders" style="border: cornflowerblue 2px solid">
        <q-card-section>
          <img src="../assets/cp_agent.svg">
          <br>
          <span class="text-h6">Thank You! We will be in touch</span>
          <br>
          <span class="text-subtitle2 text-weight-light">
              We will endeavor to call you at the requested time. However, if we are unable to reach you at that time, we will call you as soon as possible.
          </span>
        </q-card-section>
        <q-card-actions align="center">
          <q-btn label="OK" style="min-width: 100px" rounded class="bgBtnLiner text-white" @click="close"/>
        </q-card-actions>
      </q-card>
    </q-dialog>

  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'arrangement-callback',
  data () {
    return {
      sent: false,
      form: {
        is_long_term: false,
        can_payment_today: false,
        can_payment_amount: '',
        monthly_payment_amount: '',
        instalments: '',
        date: '',
        time: '',
        desc: ''
      },
      description: '',
      timeOptions: [
        {
          startTime: 8,
          title: '8:00 - 10:00'
        },
        {
          startTime: 10,
          title: '10:00 - 12:00'
        },
        {
          startTime: 12,
          title: '12:00 - 2:00'
        },
        {
          startTime: 14,
          title: '2:00 - 5:00'
        }
      ]
    }
  },
  methods: {
    isHoliday (current) {
      return this.holidays.includes(current.format('YYYY-MM-DD'))
    },
    async onSubmit () {
      this.form.desc = this.description
      if (this.form.desc) {
        this.form.desc += '. '
      }

      this.form.desc += 'I want change my payment plan. my parameters are: \n'
      this.form.desc += 'is long term = ' + this.form.is_long_term + ',\n'
      this.form.desc += 'can make payment today = ' + this.form.can_payment_today + ',\n'
      this.form.desc += 'possible payment amount = ' + this.form.can_payment_amount + ',\n'
      this.form.desc += 'monthly payment amount = ' + this.form.monthly_payment_amount + ',\n'
      this.form.desc += 'instalments = ' + this.form.instalments

      this.form.date = moment(this.form.date, 'DD MMMMM')
      this.sent = await this.$dispatch({
        action: 'customer/requestCallback',
        message: 'Requesting callback...',
        successMessage: 'Callback request sent. An agent will be in touch shortly',
        params: this.form
      })

      if (this.sent) {
        window.dataLayer.push({ event: 'requested_callback' })
      }
    },
    close () {
      this.sent = false
      this.$emit('success')
    }
  },
  computed: {
    holidays () {
      return this.$store.state.holidays.holidays
    },
    dateOptions () {
      let dates = []
      let current = moment()

      if (current.hour() > 14) {
        current = current.add(1, 'days')
      }

      for (let i = 0; i < 14; i++) {
        if (current.day() > 0 && current.day() < 6 && !this.isHoliday(current)) {
          dates.push(current.format('DD  MMMM'))
        }
        current = current.add(1, 'days')
      }
      return dates
    },

    currentTimeOptions () {
      if (!moment().isSame(moment(this.form.date, 'DD MMMM'), 'days')) {
        return this.timeOptions.map(t => t.title)
      }
      return this.timeOptions
        .filter(t => moment().hour() < t.startTime)
        .map(t => t.title)
    }
  }
}
</script>

<style scoped>

</style>
