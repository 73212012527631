export default {
  async mounted () {
    await this.$dispatch({
      action: 'cards/getCards',
      message: 'Loading Cards...'
    })

    if (!this.defaultCard) {
      this.$q.notify({
        color: 'red-5',
        message: 'Sorry, no valid credit card was found. Please add card and try again',
        timeout: 3000,
        textColor: 'white',
        icon: 'warning'
      })

      this.$emit('failure')
    }

    this.form.card = this.defaultCard
  },
  computed: {
    cards () {
      return this.$store.state.cards.cards ? this.$store.state.cards.cards.filter(card => card.isValid()).map(card => {
        return {
          label: `${card.type()} ${card.lastDigits()}`,
          value: card.id(),
          isDefault: card.isDefault()
        }
      }) : []
    },
    defaultCard () {
      return this.cards.find(card => card.isDefault)
    }
  }
}
